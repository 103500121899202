.setup-block {
  margin: auto 0;
  width: 100%;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.setup-block-padding {
  padding-top: 30px;
}

.fields-selector-popup-button {
  font-size: 15px;
  border-radius: 7px;
  border: none;
  background-color: rgba(224, 224, 224, 0.68);
  color: #000000;
  height: 50px;
  font-weight: 400;
  transition: .3s;
  cursor: pointer;
  &:disabled {
    opacity: 50%;
    cursor: auto !important;
  }
  &:hover {
    background-color: rgba(224, 224, 224, 1);
    cursor: pointer;
  }

}

.install_guide_link {
  color: #007eff;
  font-size: 16px;
  font-weight: 400;
  text-align: right;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
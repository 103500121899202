.submit-button {
  border-radius: 7px;
  border: none;
  background-color: #78deed;
  color: #000000;
  height: 50px;
  font-weight: 400;
  font-size: 15px;
  transition: .3s;
  cursor: pointer;
  &:hover {
    background-color: #8de4f1;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  &:active {
    background-color: #5ed9e8;
  }
}
.main {
    margin: 80px auto 0px auto;
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    gap: 15px;

    .main_container {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 50px;

        .settings {
            width: 400px;
            gap: 15px;
            display: flex;
            flex-direction: column;

            .submit {
                width: 100%;
            }

            .download_extension_button {
                font-size: 15px;
                border-radius: 7px;
                border: none;
                background-color: rgba(224, 224, 224, 0.68);
                color: #000000;
                height: 50px;
                font-weight: 400;
                transition: .3s;
                cursor: pointer;

                &:hover {
                    background-color: rgba(224, 224, 224, 1);
                    cursor: pointer;
                }
            }

            .tokens_editor {
                width: 100%;
                .tokens_popup_header {
                }
                .tokens_popup_add_row {
                    margin-top: 15px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    .tokens_popup_add_row_input {
                        width: 100%;
                    }
                    button {
                        aspect-ratio: 1 / 1;
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
                .tokens_list {
                    font-size: 16px;
                    margin-top: 20px;;
                    width: 100%;
                    border: none;
                    margin-bottom: 20px;
                    border-spacing:0;
                    border-radius: 7px;
                    overflow: hidden;
                    font-weight: bolder;

                    thead th {
                        font-weight: bold;
                        text-align: left;
                        border: none;
                        padding: 10px 15px;
                        background: #d8d8d8;
                        font-size: 14px;
                    }
                    tbody td {
                        text-align: left;
                        padding: 10px 15px;
                        font-size: 14px;
                        vertical-align: top;
                    }
                    thead tr th:first-child, .table tbody tr td:first-child {
                        border-left: none;
                    }
                    thead tr th:last-child, .table tbody tr td:last-child {
                        border-right: none;
                        width: 40px;
                    }
                    .token_delete {
                        cursor: pointer;
                        color: red;
                        &:hover {
                            color: rgba(255, 0, 0, 0.49);
                        }
                    }
                    .token_copy {
                        width: 30px;
                        cursor: pointer;
                        color: #1976d2;
                        text-align: center;
                        vertical-align: middle;

                        &:hover {
                            opacity: 50%;
                        }
                        &:active {
                            opacity: 100% !important;
                        }
                        svg {
                            margin-top: 2px;
                            height: 15px;
                            width: auto;
                            stroke-width: 2.5 !important;
                        }
                    }
                    tbody tr:nth-child(even){
                        background: #f3f3f3;
                    }
                }
            }

            .ext_install_guide_link {
                color: #007eff;
                font-size: 16px;
                font-weight: 400;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .lessons {
            display: flex;
            flex-direction: column;
            text-align: right;
            gap: 15px;

            .lesson {
                width: 500px;
                height: 300px;
                border-radius: 7px;
                overflow: hidden;

                iframe {
                    width: 100%;
                    height: 100%;
                }
            }

            .all_lessons_button {
                font-size: 15px;
                border-radius: 7px;
                border: none;
                background-color: #e0beff;
                color: #000000;
                height: 50px;
                font-weight: 400;
                transition: .3s;
                cursor: pointer;

                &:hover {
                    background-color: #c293f5;
                    cursor: pointer;
                }

            }
        }
    }
}
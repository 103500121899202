@import 'config';
@import './fonts/stylesheet.css';
@import 'reset';

body{
  background: #FFFFFF;
  font-family: 'Museo Sans', sans-serif;
  font-size: 13px;
  padding: 10px;
  box-sizing: border-box;
  font-weight: 300;
}

.center-main {
  margin: 80px auto 0px auto;
  display: flex;
  justify-content: center;
  max-width: 700px;
  min-height: 83vh;
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.title {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
}
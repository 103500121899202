.fields-selector {
  .fields-selector-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
  }
  .fields-selector-comment {
    font-size: 16px;
  }
  .fields-selector-list {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .field-selector {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      .field-selector-title {
        font-size: 16px;
        font-weight: 300;
        margin: auto 0;
        width: 35%;
        color: #000000;
        border: 1px solid #d0d0d0;
        border-radius: 7px;
        padding: 10px 20px;
        height: 100%;
      }
      .field-selector-arrow {
        margin: auto 0;
        svg {
          height: 35px;
          width: auto;
        }
      }
      .field-selector-selector {
        width: 55%;
      }
      .InputFromUI {
        min-height: 0 !important;
      }
    }
  }
}